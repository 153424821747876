<template>
  <!-- 新增患病-->
  <div class="contain">
    <div>
      <el-page-header
        style="margin-bottom: 1.04167vw"
        @back="goBack"
        :content="'发病/患病率/查看'"
      >
        <template slot="title">
          <i class="el-icon-arrow-left"></i>返回
        </template>
      </el-page-header>
    </div>
    <div class="form">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-position="right"
        :label-width="labelWidth"
        class="demo-ruleForm"
      >
        <el-form-item label="班级:" prop="classId">
          <el-select v-model="ruleForm.classId" placeholder="请选择班级">
            <el-option
              v-for="item in moduleClassOptions"
              :key="item.label"
              :label="item.name"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名:" prop="studentName">
          <el-input
            v-model="ruleForm.studentName"
            :onkeyup="
              (ruleForm.studentName = ruleForm.studentName.replace(/\s+/g, ''))
            "
            class="elInput"
            maxlength="9"
            placeholder="请输入姓名"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="患病日期:" prop="morbidityDate">
          <el-date-picker
            type="date"
            placeholder="请选择患病日期"
            v-model="ruleForm.morbidityDate"
            value-format="yyyy-MM-dd"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="疾病名称:" prop="morbidityName">
          <el-select
            v-model="ruleForm.morbidityName"
            placeholder="请选择疾病名称"
          >
            <el-option
              v-for="item in diseaseOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="应对措施:" prop="measure">
          <el-input
            v-model="ruleForm.measure"
            :onkeyup="(ruleForm.measure = ruleForm.measure.replace(/\s+/g, ''))"
            type="textarea"
            class="elInput"
            placeholder="请输入应对措施"
            clearable
            maxlength="199"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="form-btn">
        <el-button class="cancel" @click="onCancel">取消</el-button>
        <el-button class="submit" @click="onSubmit('ruleForm')">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        classId: "", //班级
        studentName: "", //姓名
        morbidityDate: "", //日期
        morbidityName: "", //疾病名称
        measure: "", //应对措施
      },
      rules: {
        classId: [{ required: true, message: "请选择班级", trigger: "blur" }],
        studentName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        morbidityDate: [
          { required: true, message: "请选择患病日期", trigger: "blur" },
        ],
        morbidityName: [
          { required: true, message: "请选择疾病名称", trigger: "blur" },
        ],
        measure: [{ required: true, message: "请输入措施", trigger: "blur" }],
      },
      contagionOptions: [], //疾病名称
      moduleClassOptions: [], //班级列表
      diseaseOptions: [], //疾病下拉
      morbidityId: "",
      pickerOptions: {
        disabledDate(times) {
          return times.getTime() > Date.now();
        },
      },

      labelWidth: "120px",
    };
  },

  mounted() {
    if (this.$route.query.morbidityId) {
      this.morbidityId = this.$route.query.morbidityId;
      this.getMorbidityQueryById(this.morbidityId);
    }

    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "80px";
      } else {
        this.labelWidth = (120 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
    if (window.innerWidth < 1300) {
      this.labelWidth = "80px";
    } else {
      this.labelWidth = (120 / 1920) * window.innerWidth + "px";
    }

    this.getDiseaseOptionsList();
    this.getClassInfoList();
  },

  methods: {
    //返回上一页
    goBack() {
      this.$router.go(-1);
    },

    //编辑回显
    getMorbidityQueryById(morbidityId) {
      this.$api.getMorbidityQueryById(morbidityId).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          let ruleFormobj = res.data.data;

          for (const key in this.ruleForm) {
            this.ruleForm[key] = ruleFormobj[key];
            console.log(ruleFormobj[key]);
            if (
              Object.prototype.toString.call(ruleFormobj[key]) ==
              "[object Object]"
            ) {
              if (ruleFormobj[key].value) {
                this.ruleForm[key] = ruleFormobj[key].value;
              }
            }
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查询班级
    getClassInfoList() {
      let params = {
        parentLabel: "",
      };
      this.$api.getClassInfo(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.moduleClassOptions = res.data.data;
          this.$nextTick(() => {
            if (this.$route.query.classlookId) {
              this.ruleForm.classId = this.$route.query.classlookId;
            }
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查询疾病
    getDiseaseOptionsList() {
      let params = {
        type: "morbidity_name",
      };
      this.$api.getDictionary(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.diseaseOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //取消
    onCancel() {
      this.goBack();
    },

    //提交
    onSubmit(ruleForm) {
      console.log(this.ruleForm);

      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let params = this.ruleForm;
          params.morbidityId = this.morbidityId;
          this.$api.getSaveOrUpdate(params).then((res) => {
            if (res.data.code == 0) {
              console.log(res);
              this.goBack();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "80px";
      } else {
        this.labelWidth = (120 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";

::v-deep .el-upload {
  width: 192px;
  height: 108px;
  line-height: 108px;
}
.contain {
  padding: 34px 30px 30px 30px;

  /deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #003685;
    background: #003685;
  }

  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: #003685;
  }

  /deep/.el-radio {
    font-size: 14px;
    font-weight: 400;
    color: #010334;
  }

  .or-case {
    display: flex;
    align-items: center;
    margin-top: 30px;

    .or-case-title {
      margin-right: 30px;
      font-size: 16px;
      font-weight: 400;
      color: #606266;
    }
    .or-case-con {
    }
  }

  .form {
    width: 80%;

    /deep/.el-form-item .el-select {
      width: 100%;
      height: 40px;
    }

    /deep/.el-form-item__label {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      color: #606266;
    }
    /deep/.el-input--suffix .el-input__inner {
      padding-right: 30px;
    }
    /deep/.el-input--suffix .el-input__inner,
    /deep/.el-textarea__inner {
      font-size: 14px;
      font-weight: 400;
      color: #010334;
    }

    /deep/.el-textarea__inner {
      height: 80px;
    }
  }

  .form-btn {
    margin-top: 43px;
    display: flex;
    padding-left: 100px;

    .cancel {
      width: 137px;
      height: 40px;
      border: 1px solid #b5bdcf;
      border-radius: 4px;
      font-size: 14px;
      line-height: 14px;
      color: #222222;
      opacity: 1;
    }

    .submit {
      display: block;
      width: 137px;
      height: 40px;
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }
}
</style>
